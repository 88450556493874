import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { showSpinner, hideSpinner } from 'src/app/shared/store/spinner-store/spinner.actions';
import { WindowService } from '../services/window.service';
declare let window: any;


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private totalRequests = 0; 
    constructor(private store: Store, private windowService: WindowService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.totalRequests++;
        window.prerenderReady = false;
        this.store.dispatch(showSpinner());
        return next.handle(req).pipe(
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests === 0) {
                    this.windowService.windowRef.prerenderReady = true;
                }
                this.store.dispatch(hideSpinner());
            })
        );
    }
}
