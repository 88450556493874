<div class="container">
  <div class="row">
    <!-- Article Content-->
    <div class="col-xl-9 pb-100">
      <div class="card card-default">
        <div class="card-body">
          <div class="post-edit">
            <app-content-editor (save)="onSave()" (cancel)="onCancel()" [isFormValid]="postForm.valid">
              <form [formGroup]="postForm">
                <div class="form-group">
                  <p>Title</p>
                  <input id="title" class="mb-3 form-control form-control-lg" formControlName="title" />
                  <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)" class="alert alert-danger">
                    Title is required.
                  </div>
                </div>
                <div class="form-group">
                    <p>Slug</p>
                    <input id="slug" class="mb-3 form-control form-control-lg" formControlName="slug" placeholder="Leave blank to autogenerate the slug based on the title." />
                  </div>
                <div class="form-group mb-3">
                  <p>Body</p>
                  <summernote formControlName="content" name="content" airMode [ngxSummernote]="config"></summernote>
                  <div *ngIf="f.content.invalid && (f.content.dirty || f.content.touched)" class="alert alert-danger">
                    Post body is required.
                  </div>
                </div>
                <div class="form-group mb-3">
                    <p>Additional Searchable Content for Algolia Search Service</p>
                    <small>This content is not publically viewable.</small>
                    <textarea id="searchable_content" class="form-control" formControlName="searchable_content" rows="5" style="height: 200px; width: 100%;"></textarea>
                </div>
                <div class="form-group mb-3">
                  <label for="publishedAt">Publish Post On:</label>
                  <input type="text"
                  class="form-control published-at-input"
                         bsDatepicker
                         formControlName="published_at"
                         id="publishedAt"
                         [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
                         <div *ngIf="f.published_at.invalid && (f.published_at.dirty || f.published_at.touched)" class="alert alert-danger">
                          Publish date is required.
                        </div>
                </div>
              </form>
            </app-content-editor>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card card-default mb-3">
        <div class="card-body">
          <p class="lead">Article Data</p>
          <app-meta-data [seoData]="post" (seoDataChange)="onSeoDataChange($event)"></app-meta-data>
        </div>
      </div>
      <div class="card card-default mb-3">
        <div class="card-body">
          <p class="lead">Keywords</p>
          <app-keywords [initialKeywords]="post?.keywords" (keywordsChange)="onKeywordsChange($event)"></app-keywords>
        </div>
      </div>
      <div class="card card-default"><div class="card-body">
        <p class="lead">Feature Image</p>
        <app-image-upload [currentImageUrl]="post?.image"  (onImageSelected)="handleImageSelected($event)"></app-image-upload>
      </div></div>
    </div>
  </div>
</div>
