import { createReducer, on } from '@ngrx/store';
import * as CategoryActions from './category.actions';
import { adapter, initialState } from './category.state';

export const CategoryFeatureKey = 'categories';

export const CategoryReducer = createReducer(
  initialState,
  on(CategoryActions.loadCategories, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CategoryActions.loadCategoriesSuccess, (state, { categories }) =>
    adapter.upsertMany(categories, state)
  ),
  on(CategoryActions.loadCategoriesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CategoryActions.loadCategoryChildren, (state, { categoryId }) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(CategoryActions.loadCategoryChildrenSuccess, (state, { categoryId, children }) => {
    return adapter.updateOne({
        id: categoryId,
        changes: { children }
    }, state);
}),
  on(CategoryActions.loadCategoryChildrenFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CategoryActions.resetCategories, () => initialState),
  on(CategoryActions.saveCategoryFormState, (state, { category }) => ({
    ...state,
    formState: category,
  })),
  on(CategoryActions.clearCategoryFormState, (state) => {
    return { ...state, formState: null };
  }),
  on(CategoryActions.loadCategoryFormState, (state) => {
    return state;
  }),
);
