import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { ProductSchema } from 'src/app/shared/models/schema-types.interface';
import { Media } from '../../models/media.interface';
import { Product } from '../../models/product.interface';
import { OptionHashOption } from 'src/app/features/products/models/option-hash.interface';
import * as MediaActions from '../../store/media.actions';
import { Store } from '@ngrx/store';
import { HtmlToTextPipe } from 'src/app/shared/pipes/html-to-text.pipe';
@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnChanges, OnInit {
  @Input() product: Product;
  @Input() media: Media[];
  @Input() resources: Media[];
  @Input() filteredProduct: Product[];
  @Input() selectedOptions: { [optionCode: string]: string };
  @Input() isOptionAvailable$: (optionCode: string, optionValue: string) => Observable<boolean>;
  @Input() isOptionSelected$: (optionCode: string, optionValue: string) => Observable<boolean>;
  @Input() isOptionDisabled$: (optionCode: string, optionValue: string) => Observable<boolean>;
  @Input() optionHash: Dictionary<OptionHashOption>;
  @Output() onOptionChange = new EventEmitter<{ optionCode: string, optionValueSlug: string }>();

  @Input() filteredOptions: { [optionCode: string]: string[] };
  @Input() variationOptions: { [optionCode: string]: string[] };
  @Input() filteredProductVariations: Product[];

  breadcrumbs: Breadcrumb[] = [];
  public pageUri: string;
  public pageName: string;

  constructor(
    private metaTagsService: MetaTagsService,
    private schemaService: SchemaService,
    private store: Store,
    private htmlToTextPipe: HtmlToTextPipe,
  ) {
  }
  ngOnInit(): void {
    const newMetaTags = {
      title: this.product?.meta_title,
      description: this.product?.meta_description,
      keywords: '',
      'og:title': this.product?.title,
      'og:description': this.htmlToTextPipe.transform(this.product.meta_description),
      'og:image': this.product?.images[0].original,
      'og:type': 'product',
      'og:url': window.location.href,
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionHash && changes.optionHash.currentValue) {
      // console.log('optionHash: ', changes.optionHash.currentValue);
    }

    if (changes.product && this.product) {
      const newMetaTags = {
        title: this.product.meta_title,
        description: this.product.meta_description,
        keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
      };
      this.pageName = this.product.meta_title;
      this.pageUri = window.location.href;
      this.metaTagsService.updateMetaTags(newMetaTags);
      this.breadcrumbs = [
        { label: 'Home', url: '/' },
        { label: this.product.product_name, url: '/product/' + this.product.slug },
      ];
    }

    if (changes.product && this.product) {
      const productSchema: ProductSchema = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        'name': this.product.title,
        'description': this.product.meta_description,
        'sku': this.product.upc,
        'brand': {
          '@type': 'Brand',
          'name': 'Scinomix',
        }
      };

      this.schemaService.updateSchema(productSchema);
    }
  }

  handleOptionChange(event: { optionCode: string, optionValueSlug: string }) {
    this.onOptionChange.emit(event);
  }
}
