import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { showSpinner, hideSpinner } from './spinner.actions';

@Injectable()
export class SpinnerEffects {
    showSpinner$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(/* action to start loading */),
                tap(() => this.store.dispatch(showSpinner()))
            ),
        { dispatch: false }
    );

    hideSpinner$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(/* success or failure action */),
                tap(() => this.store.dispatch(hideSpinner()))
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private store: Store) { }
}
