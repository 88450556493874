// spinner.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { showSpinner, hideSpinner, resetSpinner } from './spinner.actions';
import { initialSpinnerState } from './spinner.state';
export const spinnerFeatureKey = "spinner";
export const spinnerReducer = createReducer(
  initialSpinnerState,
  on(showSpinner, (state) => ({ activeRequests: state.activeRequests + 1 })),
  on(hideSpinner, (state) => ({ activeRequests: Math.max(0, state.activeRequests - 1) })),
  on(resetSpinner, () => initialSpinnerState)
);
