<div class="container">
    <div class="ro">
        <div class="col-xl-9 pb-100">
            <div class="card card-default">
                <div class="card-body">
                    <div class="category-edit">
                        <app-content-editor [disableSave]="false" (save)="onSave()" (cancel)="onCancel()" [isFormValid]="categoryForm.valid">
                            <form [formGroup]="categoryForm">
                                <div class="form-group">
                                  <p>Title</p>
                                  <input id="name" class="mb-3 form-control form-control-lg" formControlName="name" />
                                  <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                                    Title is required.
                                  </div>
                                </div>
                                <div class="form-group mb-3">
                                    <p>Description</p>
                                    <summernote formControlName="description" name="description" airMode [ngxSummernote]="config">
                                    </summernote>
                                    <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)"
                                      class="alert alert-danger">
                                      Catgory description is required.
                                    </div>
                                  </div>
                            </form>
                        </app-content-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
