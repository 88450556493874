import { Component, Input } from '@angular/core';
import { Post } from 'src/app/shared/models/post.interface';

@Component({
  selector: 'app-post-list-widget',
  templateUrl: './post-list-widget.component.html',
  styleUrls: ['./post-list-widget.component.scss']
})
export class PostListWidgetComponent {
  @Input() posts: Post[] = [];
  @Input() maxArticles: number = 0;
}
