import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Post } from "../../../../shared/models/post.interface";
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { generateSlug } from "src/app/core/utils/string-utils";
import { format, formatISO, parse, parseISO } from "date-fns";
import { Store, select } from "@ngrx/store";
import { loadPostFormState, savePostFormState } from "../../store/post.actions";
import { selectFormState } from "../../store/post.selectors";
import { DateUtils } from "src/app/core/utils/date-utils";
import { SeoData } from "src/app/shared/models/seo-data.interface";
@Component({
  selector: "app-post-manage",
  templateUrl: "./post-manage.component.html",
  styleUrls: ["./post-manage.component.scss"],
})
export class PostManageComponent implements OnInit {
  @Input() post: Post;
  @Output() save = new EventEmitter<{
    post: Post;
    keywords: { create: any[]; edit: any[]; delete: any[] };
    imageFile: File;
  }>();
  @Output() cancel = new EventEmitter<void>();
  isEditMode: boolean = false;
  postForm: FormGroup;
  selectedImage: File = null;
  public editorDisabled = false;
  keywordChanges: { create: any[]; edit: any[]; delete: any[] };
  isSettingValue = false;

  get f() {
    return this.postForm.controls;
  }

  public summernoteInit(event) {
    // console.log(event);
  }

  onSeoDataChange(seoData: SeoData) {
    this.post = {
      ...this.post,
      ...seoData,
    };
  }

  handleImageSelected(file: File | File[]) {
    if (Array.isArray(file)) {
      // If file is an array, take only the first file
      this.selectedImage = file[0];
    } else {
      // If file is a single File object, use it as is
      this.selectedImage = file;
    }
  }

  onKeywordsChange(keywordChanges: { create: any[]; edit: any[]; delete: any[] }) {
    this.keywordChanges = keywordChanges;
  }

  config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
        ["delete", ["deleteRow", "deleteCol", "deleteTable"]],
      ],
      image: [
        ["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
        ["float", ["floatLeft", "floatRight", "floatNone"]],
        ["remove", ["removeMedia"]],
      ],
      link: [["link", ["linkDialogShow", "unlink"]]],
      air: [["font", ["bold", "italic", "underline", "strikethrough", "superscript", "subscript", "clear"]]],
    },
    height: "600px",
    uploadImagePath: "/api/upload",
    toolbar: [
      ["misc", ["codeview", "undo", "redo", "codeBlock"]],
      ["font", ["bold", "italic", "underline", "strikethrough", "superscript", "subscript", "clear"]],
      ["style", ["style"]],
      ["fontsize", ["fontname", "fontsize", "color"]],
      ["para", ["style0", "ul", "ol", "paragraph", "height"]],
      ["insert", ["table", "picture", "link", "video", "hr"]],
      ["customButtons", ["testBtn"]],
    ],
    codeviewFilter: false,
    codeviewFilterRegex:
      /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
    styleTags: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "pre", "blockquote"],
    fontNames: ["Poppins", "Heebo", "Nunito Sans", "sansans-serif"],
    fontNamesIgnoreCheck: ["Poppins", "Heebo", "Nunito Sans"],
    styleWithSpan: false,
    dialogsInBody: true
  };



  ngOnInit(): void {
    // Populate the form with actual data if post is available
    if (this.post && this.post.slug) {
      this.isEditMode = true;
      let publishedAtDate;

      // Check if published_at exists and is not null
      if (this.post && this.post.published_at) {
        // Parse the "MM-DD-YYYY" string to a Date object
        publishedAtDate = DateUtils.toMMDDYYYY(this.post.published_at);
      } else {
        // Provide a default Date object or leave it as null/undefined
        publishedAtDate = new Date(); // or new Date() for the current date
      }
      this.postForm.patchValue({
        title: this.post.title,
        content: this.post.content,
        slug: this.post.slug,
        searchable_content: this.post.searchable_content,
        published_at: publishedAtDate,
      });
    }

    this.postForm.valueChanges.subscribe((post) => {
      if (!this.isSettingValue) {
        this.store.dispatch(savePostFormState({ post }));
      }
    });

    this.store.dispatch(loadPostFormState());

    // Select the formState from the store and use it to set the form values
    this.store.pipe(select(selectFormState)).subscribe((formState) => {
      if (formState) {
        this.isSettingValue = true; // Add this line
        this.postForm.patchValue(formState);
        this.isSettingValue = false; // Add this line
      }
    });
  }

  constructor(private fb: FormBuilder, private store: Store) {
    let publishedAtDate;

    // Check if published_at exists and is not null
    if (this.post && this.post.published_at) {
      // Parse the "MM-DD-YYYY" string to a Date object
      publishedAtDate = DateUtils.toMMDDYYYY(this.post.published_at);
    } else {
      // Provide a default Date object or leave it as null/undefined
      publishedAtDate = new Date();
    }
    this.postForm = this.fb.group({
      title: new FormControl(this.post?.title, Validators.required),
      content: new FormControl(this.post?.content, Validators.required),
      slug: new FormControl(this.post?.slug),
      searchable_content: new FormControl(this.post?.searchable_content),
      blog: new FormControl(this.post?.blog | 1),
      author: new FormControl(this.post?.author.id | 1),
      published_at: new FormControl(publishedAtDate, Validators.required),
    });
  }

  onSave() {
    // console.log(this.postForm)
    if (this.postForm.valid) {
      const formValue = { ...this.postForm.value };

      if (formValue.published_at) {
        const dateTimeString = DateUtils.toISO8601(formValue.published_at);

        if (dateTimeString) {
          formValue.published_at = dateTimeString;
        } else {
          formValue.published_at = new Date();
        }
      }

      // console.log(formValue);
      const updatedPost = {
        ...this.post,
        ...formValue,
      };

      this.save.emit({ post: updatedPost, keywords: this.keywordChanges, imageFile: this.selectedImage });
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
