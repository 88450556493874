import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  @Input() currentImageUrl: string | null = null;
  @Input() multiple: boolean = false;
  @Output() onImageSelected: EventEmitter<File | File[]> = new EventEmitter();

  handleFileInput(event: any) {
    const files = event.target.files;
    if (this.multiple) {
      this.onImageSelected.emit(files);
    } else {
      this.onImageSelected.emit(files[0]);
    }
  }
}
